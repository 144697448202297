<template>
  <v-card :loading="loading" class="ma-1 mb-2 pa-3 d-flex flex-column items-center">
    <div class="d-flex items-center">
      <div class="pa-1 mr-2">
        <v-icon x-large color="secondary">{{ icon }}</v-icon>
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <h3 class="secondary--text" v-if="renamer.show == false">
          {{ currentFile.name }}
        </h3>
        <v-text-field
          v-else
          @keydown.enter="renamer.resolve"
          @keydown.esc="renamer.reject"
          v-model="renamer.name"
          filled
          dense
          hide-details
        />
        <v-subheader class="pa-0" style="height: auto">
          {{ currentFile.path }}
        </v-subheader>
      </div>
      <div class="d-flex flex-column">
        <v-subheader class="pa-0" style="height: auto">
          {{ currentFile.size }}
        </v-subheader>
      </div>
    </div>
    <div class="d-flex align-center flex-wrap mt-2" style="gap: 10px">
      <v-btn :loading="loading" color="secondary" small rounded @click.stop="downloadDocument(true)">
        <v-icon left>mdi-eye</v-icon>
        Ver
      </v-btn>
      <v-btn :loading="loading" color="secondary" small rounded @click.stop="downloadDocument(false)">
        <v-icon left>mdi-download</v-icon>
        Descargar
      </v-btn>
      <v-btn :loading="loading" small rounded @click.stop="renameDocument">
        <v-icon left>mdi-pencil</v-icon>
        Renombrar
      </v-btn>
      <v-btn :loading="loading" color="error darken-1" small rounded @click="$emit('deleteFolder', currentFile)" v-if="sub != 'final' || $root.acceso('EDITAR_ESTUDIOS')" >
        <v-icon left>mdi-delete-outline</v-icon>
        Eliminar
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import extensions from "@/utils/mimeExtensions";
import { downloadFile } from "@/utils/index";

const icons = {
  html: "mdi-language-html5",
  js: "mdi-nodejs",
  json: "mdi-code-json",
  md: "mdi-language-markdown",
  pdf: "mdi-file-pdf-box",
  png: "mdi-file-image",
  txt: "mdi-file-document-outline",
  xlsx: "mdi-file-excel",
  zip: "mdi-folder-zip-outline",
};

export default {
  props: {
    currentFile: Object,
    idEstudio: Number,
    sub: {
      type: String,
      default: ''
    },
  },
  computed: {
    icon() {
      return icons[this.currentFile.extension];
    },
  },
  data() {
    return {
      renamer: {
        name: this.currentFile.name,
        resolve: null,
        reject: null,
        show: false,
      },
      loading: false
    };
  },
  methods: {
    async downloadDocument(open) {
      this.loading = true
      const { data } = await axios({
        method: "GET",
        responseType: "blob",
        url: (`${process.env.VUE_APP_API_URL}/documentacion/${this.idEstudio}/${encodeURIComponent(this.currentFile.path)}`),
        params: { sub: this.sub },
      });

      const file = new File([data], this.currentFile.name, {
        type: extensions[this.currentFile.extension],
      });

      downloadFile(file, open);
      this.loading = false
    },
    renameDocument() {
      const auxPromise = () =>
        new Promise((resolve, reject) => {
          this.renamer.name = this.currentFile.name;
          this.renamer.resolve = resolve;
          this.renamer.reject = reject;
          this.renamer.show = true;
        });

      auxPromise()
        .then(async () => {
          console.log(this.renamer.name);
          this.loading = true
          
          await axios({
            url: `${process.env.VUE_APP_API_URL}/documentacion/${this.idEstudio}/${this.currentFile.path}`,
            method: "PATCH",
            data: {
              name : this.renamer.name
            },
            params: { sub: this.sub },
          });

          this.loading = false
        })
        .finally(() => {
          this.renamer.name = this.currentFile.name;
          this.renamer.resolve = null;
          this.renamer.reject = null;
          this.renamer.show = false;

          this.$emit('reload')
        });
    },
  },
};
</script>
